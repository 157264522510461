<template>
    <Splide :options="splide" class="splide">
        <SplideSlide
            v-for="(blog, i) in blogs"
            :key="i"
            class="splide__track flex justify-center"
        >
            <ul
                class="splide__list flex flex-row flex-wrap w-screen md:w-[850px] bg-[#FFFAF0] rounded-none md:rounded-md"
            >
                <li
                    class="splide__slide flex flex-col items-center justify-between pb-8 md:pb-6 w-fit md:w-full"
                >
                    <div class="w-full h-auto">
                        <img
                            :src="`${blog.img}`"
                            :alt="`${blog.alt}`"
                            class="rounded-none md:rounded-t-md"
                        />
                    </div>
                    <div
                        class="flex flex-wrap text-lg sm:text-xl text-black font-medium mx-6 sm:mx-8 md:mx-24 lg:mx-16"
                    >
                        #{{ blog.title }}
                    </div>
                    <div
                        class="text-md text-black font-medium mx-6 sm:mx-16 md:mx-24 lg:mx-16 italic"
                    >
                        {{ blog.subtitle }}
                    </div>
                    <router-link
                        :to="{
                            name: 'blogDetails',
                            params: {
                                title: blog.title,
                                subtitle: blog.subtitle,
                                url: blog.url,
                            },
                        }"
                        data-aos="fade-up"
                        data-aos-easing="lienar"
                        data-aos-duration="21500"
                        class="flex flex-self-end bg-[#EBAC0D] mx-6 sm:mx-16 md:mx-24 lg:mx-16 mt-4 md:mt-0 px-6 py-2 text-white border border-[#EBAC0D] hover:bg-[#E3A60C] hover:border-[#E3A60C] hover:text-white rounded-none md:rounded-md"
                        @click="scrollToTop"
                        >Pročitaj više</router-link
                    >
                </li>
            </ul>
        </SplideSlide>
    </Splide>
</template>

<script>
import { Splide, SplideSlide } from '@splidejs/vue-splide'
import '@splidejs/vue-splide/css'
export default {
    name: 'BlogSlider',
    props: {
        blogs: {
            type: Array,
        },
    },
    data() {
        return {
            splide: {
                type: 'loop',
                width: '80%',
                height: '550px',
                perPage: 1,
                arrows: true,
                arrowPath: 'M40 9L25 0.339746V17.6603L40 9ZM26.5 7.5L0 7.5V10.5L26.5 10.5V7.5Z',
                // arrowsBackground: 'none',
                fill: 'none',
                pagination: false,
                rewind: true,
                gap: -90,
                rewindspeed: 5000,
                breakpoints: {
                    1250: {
                        perPage: 1,
                        width: '100%',
                    },
                    768: {
                        rewind: false,
                        focus: 'center',
                        pagination: 'slider',
                        perMove: 1,
                        arrows: false,
                        width: '100vh',
                        height: '500px',
                    },
                },
            },
        }
    },
    components: {
        Splide,
        SplideSlide,
    },
    methods: {
        scrollToTop() {
            window.scrollTo(0,0);
        },
    }
}
</script>

<style>
.splide__arrow {
    background: transparent;
}

.splide__arrow svg {
    fill: #EBAC0D;
    height: 3.2rem;
    width: 3.2rem;
}

.splide.is-focus-in .splide__arrow:focus {
    outline: none;
}

button:focus {
    outline: none;
}

@media (max-width: 768px) {
    .splide-list {
        width: 100%;
    }
}
</style>
