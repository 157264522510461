<template>
    <div>
        <div
            class="flex flex-col sm:px-12 md:px-24 xl:px-80 space-y-4 justify-center text-3xl lg:text-6xl text-[#fabb1e] font-bold h-screen"
        >
            <img
                src="/sad-face-icon.svg"
                alt="sad-face-icon"
                width="100"
                class="mx-auto"
            />
            <p>404: Not found!</p>
            <p>
                Vratite se na
                <router-link to="/" class="underline hover:text-gray-400"
                    >Početnu</router-link
                >
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'notFound',
}
</script>
