<template>
    <div
        class="justify-between items-center flex flex-col lg:flex-row w-full lg:h-auto px-8 sm:px-12 md:px-24 xl:px-80 top-0 lg:top-0 py-8 md:py-8 font-['Merriweather'] bg-neutral-900/80 bg-blend-multiply"
    >
        <a
            href="/"
            class="flex flex-col lg:flex-row items-center lg:items-top lg:items-center pt-6 lg:pt-0 gap-4 lg:gap-8"
        >
            <img
                src="/src/assets/logo2.png"
                class="logo w-1/3 md:w-1/2 h-auto"
            />
            <p
                class="text-3xl md:text-2xl font-extrabold text-white italic w-[300px]"
            >
                Zagrljaj Majke
            </p>
        </a>

        <div
            class="flex flex-col lg:flex-row w-full items-center lg:justify-end lg:space-x-12 pt-16 lg:pt-0 lg:flex text-lg font-bold text-white lg:text-white text-center lg:text-end lg:items-center"
        >
            <div
                class="flex flex-col lg:flex-row space-y-6 lg:space-y-0 lg:space-x-16 pb-6 lg:pb-0"
            >
                <a
                    href="/o-meni"
                    class="hover:font-bold cursor-pointer"
                    @click="closeMenu"
                    >O meni</a
                >
                <a
                    href="/blogs"
                    class="hover:font-bold cursor-pointer"
                    @click="closeMenu"
                    >Blogovi</a
                >
                <a
                    href="/kontakt"
                    class="hover:font-bold cursor-pointer"
                    @click="closeMenu"
                    >Kontakt</a
                >
            </div>
            <img src="/src/assets/arrow-up.svg" class="bottom-48 right-8 sm:right-12 md:right-24 xl:right-80 animate-none md:animate-bounce cursor-pointer rortate-180 md:rotate-180" @click="goToTop" />
        </div>
    </div>
    <div
        class="px-8 sm:px-12 md:px-24 xl:px-80 flex flex-col-reverse gap-6 lg:flex-row lg:justify-between items-center font-['Merriweather'] bg-neutral-900/80 bg-blend-multiply p-6"
    >
        <div class="text-white text-sm pb-2">
            © 2024 Zagrljaj Majke. Sva prava pridržana.
        </div>
        <div class="flex flex-row space-x-6 lg:flex-row">
                <a
                    href="https://www.instagram.com/zagrljaj.majke/"
                    target="_blank"
                >
                    <img
                        src="/instagram-icon.svg"
                        alt="instagram-icon"
                        class="w-[20px]"
                /></a>
                <a
                    href="https://www.facebook.com/people/Zagrljaj-majke/100086016913239/"
                    target="_blank"
                >
                    <img
                        src="/facebook-square-icon.svg"
                        alt="facebook-icon"
                        class="w-[20px]"
                /></a>
        </div>
    </div>
</template>

<script>
export default {
    name: 'appHeader',
    data() {
        return {
            open: false,
        }
    },
    methods: {
        closeMenu() {
            this.open = false
        },
        toggle() {
            this.open = !this.open
        },
        goToTop() {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            })
        }
    },
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@300&display=swap');
</style>
