<template>
    <section>
        <div class="flex flex-col w-full">
            <div class="flex flex-col justify-center items-start h-[250px] md:h-[300px]">
                <h1 class="text-2xl xl:text-4xl text-left text-[#EBAC0D] leading-[3rem] px-8 sm:px-8 md:px-24 xl:px-80 pt-32 my-6">BLOGOVI</h1>
            </div>
            <div class="grid grid-cols-1 lg:grid-cols-2 gap-6 lg:gap-10 px-6 sm:px-8 md:px-24 xl:px-80 pb-12 md:pb-24">
                <BlogCard :blogs="blogs" />
            </div>
        </div>
    </section>
</template>

<script>
import BlogCard from '/src/components/BlogCard.vue'
import data from '/src/data.json'

console.log(data.blogs)

export default {
    name: 'blogsList',
    data() {
        return {
            blogs: [],
        }
    },
    components: {
        BlogCard
    },
    mounted() {
        this.getData();
    },
    methods: {
        getData() {
            this.blogs = data.blogs;
            console.log(this.blogs)
        }
    }
}
</script>
