<template>
    <div>
        <div
            class="flex flex-col sm:px-12 md:px-24 xl:px-80 space-y-4 justify-center text-3xl lg:text-5xl text-[#fabb1e] font-bold h-screen"
        >
            <img
                src="/sad-face-icon.svg"
                alt="sad-face-icon"
                width="100"
                class="mx-auto"
            />
            <p>Stranica u izradi!</p>
            <p>
                Kontaktirajte me putem
                <a
                    href="https://www.instagram.com/zagrljaj-majke/"
                    class="underline hover:text-yellow-300"
                    >Instagrama</a
                >
                ili
                <a
                    href="https://www.facebook.com/people/Zagrljaj-majke/100086016913239/"
                    class="underline hover:text-yellow-300"
                    >Facebooka</a
                >
            </p>
            <br />
            <p>
                Ili vratite se na
                <router-link to="/" class="underline hover:text-yellow-300"
                    >Početnu</router-link
                >
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'commingSoon',
}
</script>
