<template>
    <!-- <section> -->
        <div class="flex flex-col justify-start items-start h-fit lg:h-[550px] w-full transition-all duration-500 ease-out hover:scale-[1.05] shadow-[0_25px_77px_-25px_rgba(0,0,0,0.32)] rounded-md cursor-pointer"
            v-for="blog in blogs" :key="blog.url"
        >
            <img :src="`${blog.img}`" class="w-fill h-auto rounded-t-md">
            <div class="card flex flex-col justify-between items-start py-8 px-6 gap-4 h-full">
                <h1 class="text-left">{{ blog.title }}</h1>
                <p class="card-text text-left italic">{{ blog.subtitle }}</p>
                <p class="card-text text-left text-sm">{{ blog.paragraph1 }}</p>
                <router-link
                    :to="{
                        name: 'blogDetails',
                        params: {
                            url: blog.url,
                            id: blog.blog_id
                        },
                    }"
                    class="text-[#EBAC0D]"
                    @click="scrollToTop"
                    >PROČITAJ VIŠE</router-link
                >
            </div>
        </div>
    <!-- </section> -->
</template>

<script>
export default {
    name: 'BlogCard',
    props: {
        blogs: {
            type: Array,
            required: true
        }
    },
    methods: {
        sendObject() {
            const objectToSend = blog;
            this.$emit('object-emitted', objectToSend);
        },
        scrollToTop() {
            window.scrollTo(0,0);
        },
    }
}
</script>

<style scoped>
.card-text {
    display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  overflow: hidden;
}

.card {
    color: black;
}
</style>
