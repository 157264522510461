<template>
    <section>
        <div class="w-full flex px-2 sm:px-12 md:px-24 xl:px-80 bg-[url('/src/assets/pregnancy/background.jpg')] bg-bottom bg-cover bg-blend-multiply bg-[#706f6f] h-fit md:h-[100vh] top-0">
            <div data-aos="fade-up" data-aos-easing="lienar" data-aos-duration="1000"
                    class="flex flex-col self-center items-start rounded-md w-full xl:w-2/3 h-fit p-6 xl:p-16 mt-32 md:mt-16">
                    <p class="text-2xl xl:text-3xl text-left text-[#EBAC0D] leading-[3rem]">TRUDNOĆA</p>
                    <p class="leading-[2rem] md:leading-10 py-6 xl:pt-4 xl:pb-0 text-md text-white text-left">
                        Test je pokazao plusić. Osjetila sam uzbuđenje i strah. Sto pitanja se javilo. Novi život raste u
                        meni, Gospodin mi povjerava jednu novu dušu. Hoću li ja to moći? Tek mi je 21, kada rodim 22.
                        Hoću li biti loša majka, tako mlada i neiskusna? Tek sam krenula raditi u vrtiću, što sad? Kako
                        ćemo mi to? Muž me smirivao. On je uvijek realan i optimističan. Sve logički promisli i smiri
                        situaciju.
                    </p>
            </div>
            <img src="/src/assets/arrow-down.svg" class="absolute hidden md:flex items-center text-white bottom-6 left-[45%] md:left-[50%] animate-none md:animate-bounce cursor-pointer" @click="goDown" />
        </div>

        <div class="flex flex-col xl:flex-row items-center justify-center py-0 md:py-24 px-0 sm:px-12 md:px-24 xl:px-80 gap-12 w-full gap-y-0 xl:gap-y-0" id="second-section">
            <p data-aos="fade-up" data-aos-easing="lienar" data-aos-duration="1000" 
                class="leading-[2rem]  md:leading-10 px-6 pt-6 pb-8 md:py-16 text-md text-black text-left">
                    Sve su to normalne emocije kada se sazna za tako posebnu vijest. Nosiš život u sebi.
                    Odgovorna si za to malo biće. I sve te emocije i strah pokazuju zapravo koliko ti je stalo. Voliš tu
                    mrvicu koja raste u tebi. Bojiš se jer ju zapravo toliko voliš. To malo biće bit će dio tebe idućih 9
                    mjeseci, i čak i kada stigne na ovaj svijet, ostat će zauvijek dio tebe. 
            </p>
            <img src="/src/assets/pregnancy/Trudnica.jpg"
                data-aos="fade-up" data-aos-easing="lienar" data-aos-duration="1000" 
                class="h-auto md:h-[500px] w-full md:w-full rounded-none md:rounded-md order-last md:order-first" >
        </div>
        
        <div class="flex flex-col xl:flex-row items-center justify-center py-6 md:py-24 bg-green-900/50 px-0 sm:px-12 md:px-24 xl:px-80 gap-12 w-full gap-y- xl:gap-y-0">
            <p data-aos="fade-up" data-aos-easing="lienar" data-aos-duration="1000" 
                class="leading-[2rem]  md:leading-10 px-6 pt-0 pb-0 md:py-16 text-md text-white text-left">
                    Početni strah lagano se pretvara u veliku radost kada prvi put čuješ kako kuca srčeko. Sada
                    dolazi vrijeme učenja. Pronađi dobre i kvalitetne radionice za trudnice. Ne čekaj zadnji čas.
                    Nego si daj vremena da sve ono najbitnije naučiš. Znanjem ćeš si olakšati mnogo toga.
                    Znanjem ćeš umiriti sve strahove i opuštenije i radosnije iščekivati svoj porod. Ako tražiš stalnu
                    podršku i osobu koja će ti donijeti veliko znanje i mir onda obavezno pronađi svoju doulu. Ona
                    će nositi sve tvoje teškoće i strahove i pomoći da ih prevladaš. Ona će ti pokazati koliko si
                    snažna baš onda kad pomisliš da si slaba, da ne možeš više. Jer ti si žena. Ona koja nosi drugo
                    biće u sebi. Ona koja će ga donijeti na ovaj svijet. Ti si nositeljica života. Povjeren ti je veliki dar.
                    Ne boj se tražiti podršku jer zaslužuješ živjeti svoje blagoslovljeno stanje radosno i osnaženo.
                    Da uistinu osjetiš koliki je to blagoslov.
            </p>
            <img src="/src/assets/home-page/trudnoca.png"
                data-aos="fade-up" data-aos-easing="lienar" data-aos-duration="1000" 
                class="h-auto md:h-[500px] w-1/2 md:w-full rounded-md order-last md:order-last" >
        </div>

        
        <div class="flex flex-col justify-center items-center py-8 md:py-24 px-8 sm:px-12 md:px-24 xl:px-80 gap-12 bg-[#FFFAF0] w-full gap-y-4 md:gap-y-12 xl:gap-y-0">
            <p class="pb-3 md:pb-6 text-black leading-[2rem] md:leading-[3rem] text-md text-left md:text-center" 
                data-aos="fade-up" data-aos-easing="lienar" data-aos-duration="1000"
            >
                “Prije nego što te oblikovah u majčinoj utrobi, ja te znadoh; prije nego što iz krila majčina izađe,
                ja te posvetih, za proroka svim narodima postavih te.”
            </p>
            <p class="text-2xl cursive text-[#fabb1e] text-left md:text-center" 
                data-aos="fade-up" data-aos-easing="lienar" data-aos-duration="2000" 
            > 
                Jr 1, 5
            </p>
        </div>
    </section>
</template>

<script>
export default {
    name: 'pregnancyPage',
    methods: {
        goDown() {
            const section = document.getElementById("second-section");
            section.scrollIntoView({behavior: "smooth"});
        }
    }
}
</script>

