<template>
    <section>
        <div class="w-full flex px-2 sm:px-12 md:px-24 xl:px-80 bg-[url('/src/assets/birth/bg2.jpg')] bg-top bg-cover bg-blend-multiply bg-[#636363] h-fit md:h-[100vh] top-0">
            <div data-aos="fade-up" data-aos-easing="lienar" data-aos-duration="1000"
                    class="flex flex-col self-center items-start rounded-md w-full xl:w-2/3 h-fit p-6 xl:p-16 mt-32 md:mt-16">
                    <p class="text-2xl xl:text-3xl text-left text-[#EBAC0D] leading-[3rem]">POROD</p>
                    <p class="py-3 md:py-6 text-slate-50 leading-[2rem] md:leading-[3rem] text-md text-left md:text-start" 
                        data-aos="fade-up" data-aos-easing="lienar" data-aos-duration="1000"
                    >
                        "Jer ti si moje stvorio bubrege, satkao me u krilu majčinu. Hvala ti što sam stvoren tako
                        čudesno, što su djela tvoja predivna. Dušu moju do dna si poznavao, kosti moje ne bjehu ti
                        sakrite dok nastajah u tajnosti, otkan u dubini zemlje."
                    </p>
                    <p class="text-2xl cursive text-[#fabb1e] text-left md:text-center" 
                        data-aos="fade-up" data-aos-easing="lienar" data-aos-duration="2000" 
                    > 
                        Ps 139 13-15
                    </p>
            </div>
            <img src="/src/assets/arrow-down.svg" class="absolute hidden md:flex items-center text-white bottom-8 left-[50%] animate-bounce cursor-pointer" @click="goDown" />
        </div>

        <div class="flex flex-col xl:flex-row items-center justify-center pt-8 md:py-24 px-0 sm:px-12 md:px-24 xl:px-80 gap-12 w-full gap-y-0 xl:gap-y-0" id="second-section">
            <div class="flex flex-col  py-0 md:py-12">
                <p data-aos="fade-up" data-aos-easing="lienar" data-aos-duration="1000" 
                    class="leading-[2rem]  md:leading-10 px-6 pt-6 pb-8 md:pt-16 text-md text-black text-left">
                        Kako tjedni prolaze sve više razmišljaš o porodu. Ne znaš kako će izgledati, svaki je porod
                        drukčiji. Ali znaj da možeš donositi odluke i biti aktivni sudionik svoga poroda. Nemoj kao
                        mnoge reći: "Ma sve su žene rodile pa ću i ja." i pritom prepustiti drugima da vode tvoj porod.
                        Informiraj se, educiraj se, ako si potkovana znanjem onda ćeš zaista moći donositi sve odluke
                        koje se tiču tvog poroda.
                </p>
                <p  data-aos="fade-up" data-aos-easing="lienar" data-aos-duration="1000" 
                    class="leading-[2rem]  md:leading-10 px-6 pt-0 pb-8 md:pb-16 text-md text-black text-left">
                    Onda ćeš znati da imaš potpuno pravo napisati svoj plan poroda, odnijeti ga u bolnicu i tražiti da
                    ga se poštuje. Jer poštujući plan poroda poštuje se i tebe i tvoju bebu. Vodi dijalog s liječnicima,
                    pitaj ih ako nešto ne razumiješ. Nemoj dopustiti da oni vode monolog i da budeš prisiljena na
                    stvari koje nisi željela. Pripremaj se psihički na komunikaciju s osobljem. Ne dopusti da ti se
                    pogazi dostojanstvo. 
                </p>
            </div>

            <img src="/src/assets/birth/section-1.png"
                data-aos="fade-up" data-aos-easing="lienar" data-aos-duration="1000" 
                class="h-auto w-full md:w-1/3 rounded-none md:rounded-md order-last md:order-first" >
        </div>
        
        <div class="flex flex-col xl:flex-row items-center justify-center pt-6 md:py-24 bg-green-900/50 px-0 sm:px-12 md:px-24 xl:px-80 gap-12 w-full gap-y- xl:gap-y-0">
            <p data-aos="fade-up" data-aos-easing="lienar" data-aos-duration="1000" 
                class="leading-[2rem]  md:leading-10 px-6 pt-0 pb-0 md:py-16 text-md text-white text-left">
                    Zaslužuješ doživjeti porod kao blagoslovljen događaj koji ćeš nositi cijeli svoj život u lijepom
                    sjećanju. Sve mi žene imamo pravo na to i zaslužujemo divno sjećanje na porod.
            </p>
            <img src="/src/assets/birth/bg.png"
                data-aos="fade-up" data-aos-easing="lienar" data-aos-duration="1000" 
                class="h-auto md:w-1/2 w-full rounded-none md:rounded-md order-last md:order-last" >
        </div>
                <div
            class="flex flex-col xl:flex-col items-center justify-center py-6 md:py-24 px-6 sm:px-12 md:px-24 xl:px-80 gap-12 w-full gap-y-12 xl:gap-y-0"
        >
            <p
                data-aos="fade-up"
                data-aos-easing="lienar"
                data-aos-duration="1000"
                class="leading-[2rem] md:leading-10 text-md text-black text-start md:text-center w-full md:w-4/6"
            >
                U redu je da ne bude točno kako si željela. Nadaš se najboljem, ali možda ne bude baš najbolje.
                Bitno je kasnije sve izbaciti iz sebe i zacijeliti cijelu tu priču. U redu je i ako si već doživjela
                porod koji ti nije ostao u lijepom sjećanju. Ne mora ponovno biti tako. Važno je da sve rane
                izađu i da pomoću njih postaneš još jača i ovaj put možda ne mora biti tako. Važno je da
                vjeruješ sebi i svojem tijelu, da se pouzdaš u Stvoritelja koji je tu bebu u tvojem tijelu tako
                savršeno satkao. Ti možeš roditi svoju bebu. Ukoliko si zdrava trudnica sa zdravom bebom ti to
                itekako možeš. I ono najvažnije beba zna kada joj je rođendan. Ona zna kada se treba roditi.
                Imaj povjerenja u nju.
            </p>
            <p class="text-[#] leading-[2rem] md:leading-[3rem] text-left md:text-center text-xl text-[#fabb1e] md:w-4/6 pt-0" 
                data-aos="fade-up" data-aos-easing="lienar" data-aos-duration="1000"
            >
                Od srca ti želim blagoslovljen porod koji će te osnažiti, iz kojeg će se roditi jedna nova ti, jedna
                divna žena i majka. 
            </p>
        </div>
    </section>
</template>

<script>
export default {
    name: 'birthPage',
    methods: {
        goDown() {
            const section = document.getElementById("second-section");
            section.scrollIntoView({behavior: "smooth"});
        }
    }
}
</script>
