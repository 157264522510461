<template>
    <section>
        <div class="w-full flex px-2 sm:px-12 md:px-24 xl:px-80 bg-[url('/src/assets/postpartum/bg.jpg')] md:bg-[url('/src/assets/postpartum/bg2.jpg')] bg-left-top md:bg-left-center bg-cover bg-blend-multiply bg-[#706f6f] h-fit md:h-[100vh] top-0">
            <div data-aos="fade-up" data-aos-easing="lienar" data-aos-duration="1000"
                    class="flex flex-col self-center items-start rounded-md w-full xl:w-2/3 h-fit p-6 xl:p-16 mt-32 md:mt-16">
                    <p class="text-2xl xl:text-3xl text-left text-[#EBAC0D] leading-[3rem]">POSTPARTUM</p>
                    <p class="leading-[2rem] md:leading-10 py-6 xl:pt-4 xl:pb-0 text-md text-white text-left">
                        Nakon što sam rodila prvo dijete uslijedio je moj prvi postpartum i totalni šok, nepripremljenost,
                        zbunjenost. Rana od epiziotomije bila je bolna. Umor i iscrpljenost brzo su me stigli, kao i
                        manjak vitamina i željeza. Zaboravila sam potpuno na sebe i svoje potrebe. Kako da se
                        pobrinem za to malo stvorenje, kako ću znati je li gladna, je li dovoljno jela. I zaista te brige prvih
                        dana postale su stvarnost. Nije dobila na težini rekla mi je patronažna 10. dan, a meni se tada
                        zamračilo. Nisam stigla jesti to jutro, nisam još uvijek uzimala željezo, a kada sam čula njezine
                        riječi da moramo hitno pedijatru osjetila sam da mi se vrti u glavi. Legla sam na krevet dok je
                        ona sama okupala bebu. I što sada? Nakon pregleda kupila sam dohranu i počela davati jednu
                        bocu na dan. Za dojenje nisam dobila nikakav savjet. I kupila sam konačno željezo i počela
                        paziti da ne preskačem obroke.
                    </p>
            </div>
            <img src="/src/assets/arrow-down.svg" class="absolute hidden md:flex items-center text-white bottom-8 left-[50%] animate-bounce cursor-pointer" @click="goDown" />
        </div>

        <div class="flex flex-col xl:flex-row items-center justify-center py-0 md:py-24 px-0 sm:px-12 md:px-24 xl:px-80 gap-12 w-full gap-y-0 xl:gap-y-0" id="second-section">
            <p data-aos="fade-up" data-aos-easing="lienar" data-aos-duration="1000" 
                class="leading-[2rem]  md:leading-10 px-6 pt-6 pb-8 md:py-16 text-md text-black text-left">
                    S dojenjem smo se i dalje malo mučili. Tijekom trudnoće nešto jesam čitala, ali nimalo dovoljno.
                    Uz to bila sam na nekom površnom tečaju u gradu i to je sve. A nitko mi zapravo nije rekao
                    koliko je dojenje zapravo kompleksno. Nisam znala koga uopće pitati išta o dojenju. Tu je
                    krenulo moje prvo istraživanje. Iščitavanje o dojenju po internetu i po društvenim mrežama. Bila
                    sam uporna i uspjeli smo s dva mjeseca ponovno isključivo dojiti. I dojili smo do njenih 15
                    mjeseci.
            </p>
            <img src="/src/assets/postpartum/dojenje.jpg"
                data-aos="fade-up" data-aos-easing="lienar" data-aos-duration="1000" 
                class="h-auto md:h-[500px] w-full md:w-full rounded-none md:rounded-md order-last md:order-first" >
        </div>
        
        <div class="flex flex-col xl:flex-row items-center justify-center pt-6 md:py-24 bg-green-900/50 px-0 sm:px-12 md:px-24 xl:px-80 gap-12 w-full gap-y-0 xl:gap-y-0">
            <p data-aos="fade-up" data-aos-easing="lienar" data-aos-duration="1000" 
                class="leading-[2rem] md:leading-10 px-6 pt-0 pb-0 md:py-16 text-md text-white text-left">
                    Što sam naučila nakon vlastitog prvog postpartuma? Da je vrlo zahtjevno razdoblje u kojem
                    majka ne smije zaboraviti na sebe. Da, tu je beba koja postaje cijeli tvoj svijet. Ali osim bebe
                    rodila se i majka. I ta majka treba odmor, okrjepu, podršku, snagu. Tu snagu može skupiti
                    zdravom prehranom, kvalitetnim suplementima, dovoljnim spavanjem (prve tjedne zaista vrijedi
                    ono kad spava beba neka spava i majka), vremenom za sebe. Jako je bitno okružiti se
                    podrškom i ljudima koji će zaista pomoći majci sa svakom njezinom potrebom. Bitno je da majka
                    bude svjesna toga da ako je ona dobro i beba će biti dobro. Ako se ona osjeća mirnom i
                    podržanom i beba će osjetiti njezin mir. Ako se majka s ljubavlju odnosi prema sebi i beba će
                    osjetiti da je majka ljubi i voli. 
            </p>
            <img src="/src/assets/postpartum/2.jpg"
                data-aos="fade-up" data-aos-easing="lienar" data-aos-duration="1500" 
                class="h-auto md:h-[500px] w-full md:w-full rounded-none md:rounded-md order-last md:order-last" >
        </div>

        <div class="flex flex-col xl:flex-row items-center justify-center py-0 md:py-24 px-0 sm:px-12 md:px-24 xl:px-80 gap-12 w-full gap-y-0 xl:gap-y-0">
            <div class="flex flex-col justify-center items-start gap-12 gap-y-4 md:gap-y-12 xl:gap-y-0 px-6 md:px-0">
                <p data-aos="fade-up" data-aos-easing="lienar" data-aos-duration="1000" 
                    class="leading-[2rem] md:leading-10 pt-6 pb-8 md:pt-0 text-md text-black text-left">
                        Draga majko, beba je tvoj cijeli svijet, ali i ti si njezin cijeli svijet. Zato voli sebe, čuvaj se i njeguj.
                        Budi blaga prema sebi i bit ćeš najbolja majka svojoj djeci.
                </p>
                <p class="pb-3 md:pb-6 text-black leading-[2rem] md:leading-[3rem] text-md text-left md:text-left" 
                    data-aos="fade-up" data-aos-easing="lienar" data-aos-duration="1000"
                >
                    "Sinovi njezini podižu se i sretnom je nazivaju,
                    i muž njezin hvali je: <br> 《Mnoge su žene bile vrsne,
                    ali ti ih sve nadmašuješ.》"
                </p>
                <p class="text-2xl cursive text-[#fabb1e] text-left pb-8 md:pb-0" 
                    data-aos="fade-up" data-aos-easing="lienar" data-aos-duration="2000" 
                > 
                    Izr 31, 28-29
                </p>
            </div>
            <img src="/src/assets/postpartum/bg3.jpg"
                data-aos="fade-up" data-aos-easing="lienar" data-aos-duration="1000" 
                class="h-auto md:h-auto w-full md:w-1/3 rounded-none md:rounded-md order-last md:order-first" >
        </div>
    </section>
</template>

<script>
export default {
    name: 'postpartumPage.vue',
    methods: {
        goDown() {
            const section = document.getElementById("second-section");
            section.scrollIntoView({behavior: "smooth"});
        }
    }
}
</script>
