<template>
    <transition name="fade-out">    
        <section>
            <div class="bg-gray-300/50">
                <div
                    class="w-full flex px-2 sm:px-12 md:px-24 xl:px-80 bg-[url('/src/assets/about-me/Profil.jpg')] bg-center bg-cover bg-blend-multiply bg-[#5e5d5d] h-fit md:h-[100vh] top-0"
                >
                    <div
                        data-aos="fade-up"
                        data-aos-easing="lienar"
                        data-aos-duration="1000"
                        class="flex flex-col self-center items-start rounded-md w-full xl:w-2/3 h-fit p-6 xl:p-16 mt-32 md:mt-16"
                    >
                        <p
                            class="text-2xl xl:text-3xl text-left text-[#EBAC0D] leading-[3rem]"
                        >
                            Moje ime je Laura
                        </p>
                        <p
                            class="leading-[2rem] md:leading-10 pb-6 pt-3 xl:pt-4 xl:pb-0 text-md text-white text-left"
                        >
                            Moje ime je Laura. Živim s obitelji u Istri. Ono što je bila molitva moga srca u srednjoj školi jest upoznati srodnu dušu i otkriti svoj poziv - ono čime se želim baviti. I bi tako. Gospodin je bio brz, i već na prvoj godini fakulteta upoznala sam ljubav svog života. Nakon tri godine udala sam se i godinu poslije postala majka. Preko moga braka i moje dječice Gospodin me brusi u strpljenju, pravednosti, razboritosti i vjeri i uči me u svakoj situaciji, pa i onoj najlošijoj, tražiti nešto dobro.
                        </p>
                    </div>
                <img src="/src/assets/arrow-down.svg" class="absolute hidden md:flex items-center text-white bottom-8 left-[50%] animate-bounce cursor-pointer" @click="goDown" />

                </div>
            </div>

            <div
                class="flex flex-col xl:flex-row items-center justify-center py-0 md:py-24 px-0 sm:px-12 md:px-24 xl:px-80 gap-12 bg-green-900/50 w-full gap-y-12 xl:gap-y-0"
                id="second-section"
            >
                <p
                    data-aos="fade-up"
                    data-aos-easing="lienar"
                    data-aos-duration="1000"
                    class="leading-[2rem] md:leading-10 px-6 pt-0 pb-8 md:py-16 text-md text-white text-left"
                >
                Moj nevjerojatni suprug uvijek mi je velika podrška i oslonac, a moji porodi i moja ljubljena djeca omogućili su mi da bolje upoznam sebe, da spoznam svoje dobre i loše strane, i probudili u meni poziv za pomaganjem ženama, trudnicama i majkama.<br>
                    
                Oduvijek sam s divljenjem gledala trudnice, a bebe su u meni budile majčinske i one najdivnije osjećaje. Sjećam se u magli kada sam s 4 godine dobila mog dragog bracu, jako me veselilo što imamo bebu u kući, željela sam da nikad ne naraste, za razliku od mojih roditelja koji nisu baš s njim mogli puno spavati. 🤣 <br />
                Oduvijek sam jedva čekala osjetiti kako je to biti trudna i donijeti na svijet to malo stvorenje. Ali naravno, dok ne dobiješ svoju bebu mnogo toga ne znaš i taj val neznanja me s prvim porodom zapljusnuo. Nakon što sam s 22 godine rodila prvo dijete, uslijedio je moj prvi postpartum i totalni šok, nepripremljenost, zbunjenost. Unatoč tome, izronila sam ubrzo i malo pomalo učila sam i postala sigurnija i opuštenija.
                </p>
                <img
                    src="/src/assets/home-page/profil2.jpg"
                    data-aos="fade-up"
                    data-aos-easing="lienar"
                    data-aos-duration="1000"
                    class="h-auto md:h-auto w-full md:w-[60%] xl:w-[40%] p-0 sm:pt-8 rounded-md order-first md:order-last"
                />
            </div>

            <div
                class="flex flex-col md:flex-row justify-center items-center py-0 md:py-24 px-0 sm:px-12 md:px-24 xl:px-80 xl:py-40"
            >
                <img
                    src="/src/assets/about-me/kids.jpg"
                    class="relative xl:absolute w-full md:w-[600px] h-auto sm:right-12 md:right-24 xl:right-80 z-0 md:z-[-1] rounded-none md:rounded-md"
                    data-aos="fade-up"
                    data-aos-easing="lienar"
                    data-aos-duration="1500"
                />
                <div
                    class="flex w-full bg-[#FFFAF0] h-fit 2xl:h-fit rounded-none md:rounded-md mr-0 xl:mr-[450px]"
                    data-aos="fade-up"
                    data-aos-easing="lienar"
                    data-aos-duration="1000"
                >
                    <p
                        class="leading-[2rem] md:leading-10 text-black text-md text-start p-6 xl:p-12 h-auto rounded-md"
                    >
                    Završila sam Učiteljski fakultet u Rijeci, tako da sam po struci magistar ranog i predškolskog odgoja i obrazovanja, odnosno odgajatelj. Fakultet mi je donio mnogo novih spoznaja vezanih uz rani odgoj koje primjenjujem i u odgoju vlastite djece. Osim ljubavi prema ranom odgoju, kroz moje porode i moju djecu rodila se neka nova ja pa tako i ljubav prema porodima. Zaljubila sam se potpuno u svijet trudnica, rodilja, majki i shvatila koliko je predivno biti podrška i pomoć ženama u njihovom najosjetljivijem periodu.
                        <br /><br />
                    Iza mene tri su vlastita poroda koja su me obogatila na mnoge načine. Koja su mi dala mnogo iskustva i znanja, i koja su mi zapravo kao početni temelj moje buduće misije. Misije u kojoj želim nadograditi i umnožiti znanja i dalje ih širiti tako da trudnoća, porod i postpartum za svaku ženu bude nešto istinski prekrasno. Blagoslovljena sam prilično laganim i bržim porodima koji su mi ostali u lijepom sjećanju. Voljela bih da sve žene postanu svjesne da Gospodin od nas ne traži da porod doživimo kao ogromnu patnju i traumu, već upravo suprotno. Njegova je želja da porod bude blažen, moćan, prekrasan i, ukoliko je moguće, prirodan događaj u kojem se rađa jedna nova ljudska duša i jedna nanovo rođena žena - odnosno majka.
                        <br /><br />
                    Kako bih započela s učenjem i stjecanjem znanja i postala porođajni djelatnik, 2022. završila sam obuku za porodnu doulu. Zasad nisam još prošla proces certifikacije tako da sam porodna doula u treningu. Godinu nakon završila sam Advanced obuku za doulu u kojoj sam stekla certifikat za pripremu za vaginalni porođaj nakon carskog reza.
Voljela bih pomoći i ženama u periodu nakon poroda, odnosno postpartumu. S obzirom da sam se i sama uvjerila koliko je podrška s dojenjem bitna, želja mi je biti ženama na raspolaganju i pomoći im s dojenjem. Tako da trenutna edukacija koju prolazim jest od Lactation Education Resources (LER) kako bih postala Certified Breastfeeding Specialist, odnosno savjetnica za dojenje.
                    </p>
                </div>
            </div>

            <div
                class="flex flex-col md:flex-row justify-center items-center px-0 sm:px-12 md:px-24 xl:px-80 gap-12 bg-green-900/50 w-full gap-y-0 md:gap-y-12 xl:gap-y-0"
            >
                <div
                    class="w-auto text-md leading-[2rem] text-white text-left px-8 pb-8 md:pb-0 pt-8 sm:p-0"
                    data-aos="fade-up"
                    data-aos-easing="lienar"
                    data-aos-duration="2000"
                >
                Dvadeseto stoljeće i zadnja desetljeća pretjerano su počela medikalizirati porode, ono prirodno i ono nagonsko nekako je uspjelo nestati. Taj mentalitet gdje je porod bolest i gdje gotovo svaki zahtijeva nekakve lijekove mora se polako mijenjati. I vjerujem da hoće, vjerujem da Gospodin želi vratiti porodu ono što mu je i namjenio. Da se poštuje ženu, njene želje, njene nagone, njene osjećaje. Da se shvati koliko je porod povezan s emocijama, koliko je porod zapravo psihološki i emocionalni proces, a mnogo puta to se zanemari i ženu svede na objekt koji se porađa. To ne smijemo dopustiti drage moje žene. Nitko vas ne porađa, vi se porađate, drugi su tu kao asistenti. Svaka žena zaslužuje nositi lijepu uspomenu vlastitog poroda.
                </div>
                <img
                    src="/src/assets/home-page/Profil.jpg"
                    class="w-full md:w-[400px] h-auto order-first"
                    data-aos="fade-up"
                    data-aos-easing="lienar"
                    data-aos-duration="1000"
                />
            </div>

            <div
                class="flex flex-col justify-center items-center py-8 md:py-24 px-8 sm:px-12 md:px-24 xl:px-80 gap-12 bg-[#FFFAF0] w-full gap-y-4 md:gap-y-12 xl:gap-y-0"
            >
                <p
                    class="pb-3 md:pb-6 text-black text-md leading-[2rem] md:leading-[3rem] text-left md:text-center"
                    data-aos="fade-up"
                    data-aos-easing="lienar"
                    data-aos-duration="1000"
                >
                Zahvalna sam Bogu što je djelovao kroz moj brak, moje porode, moju djecu, moj svaki djelić života i doveo me na ovaj predivan put pomaganja ženama i majkama.
                </p>
                <p
                    class="pb-3 md:pb-6 text-black text-md leading-[2rem] md:leading-[3rem] text-left md:text-center"
                    data-aos="fade-up"
                    data-aos-easing="lienar"
                    data-aos-duration="1000"
                >
                Drage žene, trudnice, majke, kraljice, tu sam za vas. Kao doula, podrška, edukator, rame za plakanje. Želja moga srca jest umiriti svaki vaš strah “jer nije nam Bog dao duha bojažljivosti, nego snage, ljubavi i razbora.” Želim vam biti podrška u fizičkom, psihičkom i emocionalnom smislu - biti jednostavno doula - ona koja služi ženi.
                </p>
                <p
                    class="text-2xl cursive text-[#fabb1e] text-left md:text-center"
                    data-aos="fade-up"
                    data-aos-easing="lienar"
                    data-aos-duration="2000"
                >
                    Od srca vam šaljem zagrljaj, Laura.
                </p>
            </div>
        </section>
    </transition>
</template>

<script>
export default {
    name: 'aboutMe',
/*     data() {
        return {
            showPage: false
        }
    },
    mounted() {
        this.showPage = true;
    }, */
    methods: {
        goDown() {
            const section = document.getElementById("second-section");
            section.scrollIntoView({behavior: "smooth"});
        }
    }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;1,300&display=swap');

section {
    font-family: 'Merriweather', serif;
}
.fade-out-enter-active,
.fade-out-leave-active {
  transition: opacity 0.5s;
}

.fade-out-enter,
.fade-out-leave-to {
  opacity: 0;
}

</style>
