<template>
    <section>
        <div
            class="w-full flex px-0 sm:px-0 md:px-24 xl:px-80 bg-[url('/src/assets/blog/bg-3.png')] md:bg-[url('/src/assets/blog/bg4.png')] bg-top bg-cover bg-no-repeat md:bg-cover h-[550px] md:h-[630px] top-0"
        ></div>
        <div
            class="flex flex-col items-start justify-center text-[#fabb1e] pt-6 md:pt-24 px-6 sm:px-12 md:px-24 xl:px-80 py-0 md:py-24 gap-12 w-full gap-y-0 xl:gap-y-0"
        >
            <p
                class="text-2xl text-left md:text-center sm:text-4xl italic pb-4"
            >
                {{ objectData.title }}
            </p>
        </div>
        <div
            class="flex flex-col xl:flex-row items-center justify-center py-6 md:py-24 px-6 sm:px-12 md:px-24 xl:px-80 gap-12 bg-green-900/50 w-full gap-y-12 xl:gap-y-0"
        >
            <p
                data-aos="fade-up"
                data-aos-easing="lienar"
                data-aos-duration="1000"
                class="leading-[2rem] md:leading-10 text-md text-white text-left"
            >
                {{ objectData.paragraph1 }}
            </p>
        </div>
        <div
            class="flex flex-col xl:flex-row items-center justify-center py-0 md:py-24 px-0 sm:px-12 md:px-24 xl:px-80 gap-12 bg-[#FFFAF0] w-full gap-y-0 xl:gap-y-0"
        >
            <p
                data-aos="fade-up"
                data-aos-easing="lienar"
                data-aos-duration="1000"
                class="leading-[2rem] md:leading-10 px-6 pt-6 pb-8 md:py-16 text-md text-black text-left"
            >
               {{ objectData.paragraph2 }}
                <br />

                {{ objectData.paragraph3 }}

            </p>
            <img
                v-if="objectData.img1"
                :src="`${ objectData.img1 }`"
                data-aos="fade-up"
                data-aos-easing="lienar"
                data-aos-duration="1000"
                class="h-auto md:h-auto w-full md:w-1/3 rounded-none md:rounded-md order-first md:order-last"
            />
        </div>
        <div
            class="flex flex-col xl:flex-row items-center justify-center py-6 md:py-24 px-6 sm:px-12 md:px-24 xl:px-80 gap-12 bg-green-900/50 w-full gap-y-12 xl:gap-y-0"
        >
            <p
                data-aos="fade-up"
                data-aos-easing="lienar"
                data-aos-duration="1000"
                class="leading-[2rem] md:leading-10 text-md text-white text-left"
            >
                {{ objectData.paragraph4 }}
            </p>
        </div>
        <div
            class="flex flex-col xl:flex-row items-center justify-center py-0 md:py-24 px-0 sm:px-12 md:px-24 xl:px-80 gap-12 bg-[#FFFAF0] w-full gap-y-0 xl:gap-y-0"
        >
            <p
                data-aos="fade-up"
                data-aos-easing="lienar"
                data-aos-duration="1000"
                class="leading-[2rem] md:leading-10 px-6 pt-6 pb-8 md:py-16 text-md text-black text-left"
            >
                {{ objectData.paragraph5 }}

            </p>
            <img
                v-if="objectData.img2"
                :src="`${ objectData.img2 }`"
                data-aos="fade-up"
                data-aos-easing="lienar"
                data-aos-duration="1000"
                class="h-auto md:h-auto w-full xl:w-1/3 rounded-none md:rounded-md order-first md:order-last rotate-90"
            />
        </div>
        <div
            class="flex flex-col xl:flex-row items-center justify-center py-0 md:py-24 px-0 sm:px-12 md:px-24 xl:px-80 gap-12 bg-green-900/50 w-full gap-y-0 xl:gap-y-0"
        >
            <div>
                <p class="leading-[2rem] md:leading-10 px-6 pt-6 md:pt-16 text-lg text-white text-left" v-if="objectData.p6_title"
                    data-aos="fade-up"
                    data-aos-easing="lienar"
                    data-aos-duration="1000"
                >
                    {{ objectData.p6_title }}
                </p>
                <p
                    data-aos="fade-up"
                    data-aos-easing="lienar"
                    data-aos-duration="1000"
                    class="leading-[2rem] md:leading-10 px-6 pt-6 pb-8 md:pb-16 text-md text-white text-left"
                >
                    {{ objectData.paragraph6 }}

                </p>
            </div>

            <img
                :src="`${ objectData.img3 }`"
                data-aos="fade-up"
                data-aos-easing="lienar"
                data-aos-duration="1000"
                class="h-auto md:h-auto w-full md:w-2/5 rounded-none md:rounded-md order-first"
            />
        </div>
        <div
            class="flex flex-col items-start justify-center py-0 md:py-24 px-0 sm:px-12 md:px-24 xl:px-80 gap-12 bg-[#FFFAF0] w-full gap-y-0 xl:gap-y-0"
        >
            <p class="leading-[2rem] md:leading-10 px-6 pt-6 md:pt-16 text-lg text-black text-left" v-if="objectData.p7_title"
                data-aos="fade-up"
                data-aos-easing="lienar"
                data-aos-duration="1000"
            >
                {{ objectData.p7_title }}
            </p>
            <p
                data-aos="fade-up"
                data-aos-easing="lienar"
                data-aos-duration="1000"
                class="leading-[2rem] md:leading-10 px-6 pt-6 pb-8 text-md text-black text-left"
            >
                {{ objectData.paragraph7 }}

            </p>
            <p class="leading-[2rem] md:leading-10 px-6 pt-0 md:pt-12 text-lg text-black text-left" v-if="objectData.p8_title"
                data-aos="fade-up"
                data-aos-easing="lienar"
                data-aos-duration="1000"
            >
                {{ objectData.p8_title }}
            </p>
            <p
                data-aos="fade-up"
                data-aos-easing="lienar"
                data-aos-duration="1000"
                class="leading-[2rem] md:leading-10 px-6 pt-6 pb-8 text-md text-black text-left"
            >
                {{ objectData.paragraph8 }}

            </p>
            <p
                data-aos="fade-up"
                data-aos-easing="lienar"
                data-aos-duration="1000"
                class="leading-[2rem] md:leading-10 px-6 pt-0 pb-8 md:py-4 text-md text-black text-left"
            >
                {{ objectData.paragraph9 }}

            </p>
        </div>
        <div
            class="flex flex-col items-center justify-center py-0 md:py-24 px-0 sm:px-12 md:px-24 xl:px-80 gap-12 bg-green-900/50 w-full gap-y-0 xl:gap-y-0"
        >
            <p
                data-aos="fade-up"
                data-aos-easing="lienar"
                data-aos-duration="1000"
                class="leading-[2rem] md:leading-10 px-6 pt-6 pb-6 md:pb-4 md:pt-0 text-md text-white text-left"
            >
                {{ objectData.paragraph10 }}

            </p>
            <p
                data-aos="fade-up"
                data-aos-easing="lienar"
                data-aos-duration="1000"
                class="leading-[2rem] md:leading-10 px-6 pt-0 pb-8 md:pt-4 md:pb-0 text-md text-white text-left"
            >
                {{ objectData.paragraph11 }}

            </p>
        </div>
        <div 
            data-aos="fade-up"
            data-aos-easing="lienar"
            data-aos-duration="1000" 
            class="px-6 sm:px-12 md:px-24 xl:px-80 gap-y-6 text-[#fabb1e] text-lg">
            <div class="flex flex-col md:flex-row items-start border-b-2 border-[#fabb1e] py-8 md:py-16 gap-4 cursor-default"
            >
                <div 
                    v-for="i in objectData._tags" :key="i"
                >
                    <p class="hover:text-slate-900">{{ i }}</p>
                </div>
            </div>
        </div>
        <div
            data-aos="fade-up"
            data-aos-easing="lienar"
            data-aos-duration="1500" 
            class="flex flex-col items-start text-start text-black py-8 md:py-24 px-6 sm:px-12 md:px-24 xl:px-80 gap-y-6 break-all"
            >
            <h1 class="text-[#fabb1e] text-2xl">LITERATURA</h1>
            <div 
                v-for="j in objectData._literature" :key="j"
            >
                <a :href="`${ j.url }`" target="_blank">
                    {{ j.title }}
                </a>
            </div>
        </div>
    </section>
</template>

<script>
import data from '/src/data.json'

export default {
    name: 'blogDetails',
    data() {
        return {
            objectData: {}
        }
    },
    mounted() {
        this.fetchObjectData();
        console.log(this.objectData)
    },
    methods: {
        fetchObjectData() {
            let url = this.$route.params.url
            this.objectData = data.blogs.find(obj => obj.url === url);
        }
    }
}
</script>
