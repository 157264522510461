<template>
    <section>
        <div
            class="w-full flex px-6 sm:px-12 md:px-24 xl:px-80 bg-[url('/src/assets/home-page/bg.png')] bg-center bg-blend-multiply bg-[#7a7a79] bg-cover h-[300px] sm:h-[400px] md:h-[300px] top-0"
        >
            <div
                data-aos="fade-up"
                data-aos-easing="lienar"
                data-aos-duration="1000"
                class="flex flex-col justify-end self-center items-end md:items-start rounded-md lg:w-full xl:w-full 2xl:w-4/6 h-fit lg:h-1/2"
            >
                <p
                    class="text-3xl xl:text-4xl text-left text-white leading-[3rem] xl:leading-[3.5rem] pt-24 md:pt-0"
                >
                    Kontaktiraj me!
                </p>
            </div>
        </div>
        <div class="section pt-0 pb-12 md:pt-10 md:pb-24 px-6 md:px-24 lg:px-32 xl:px-24 2xl:px-80 flex flex-col justify-center items-center w-full gap-x-12 gap-y-6 xl:gap-y-0 w-full">
            <div class="flex flex-row self-start space-x-6 lg:flex-row w-full md:w-1/2 pt-12 md:pt-0 text-black">
                <p>Kontaktiraj me putem: </p>
                    <a
                        href="https://www.instagram.com/zagrljaj.majke/"
                        target="_blank"
                    >
                        <img
                            src="/instagram-dark.svg"
                            alt="instagram-icon"
                            class="icon w-[20px]"
                    /></a>
                    <a
                        href="https://www.facebook.com/people/Zagrljaj-majke/100086016913239/"
                        target="_blank"
                    >
                        <img
                            src="/facebook-dark.svg"
                            alt="facebook-icon"
                            class="icon w-[20px]"
                    /></a>
            </div>
            <div class="flex flex-col sm:flex-row items-start self-start w-full md:w-1/2 py-2 md:py-4 space-x-0 md:space-x-6">
                <p>Ili putem email-a:</p>
                <a href="mailto:laurabrajkovic16@gmail.com" class="underline underline-offset-8">laurabrajkovic16@gmail.com</a>
            </div>
            <form class="mt-12 w-full md:w-1/2 disabled">
                <div class="grid justify-items-start">
                    <div
                        class="text-md font-bold text-black-900 tracking-wide pb-6 px-[10px]"
                    >
                        Email
                    </div>
                    <input
                        v-model="username"
                        class="input w-full text-lg py-2 border-b bg-[#FCFCFF]"
                        type="email"
                        placeholder="name@gmail.com"
                        required
                    />
                </div>
                <div class="grid justify-items-start">
                    <div
                        class="text-md font-bold text-black-900 tracking-wide py-6 px-[10px]"
                    >
                        Naslov
                    </div>
                    <input
                        v-model="title"
                        class="input w-full text-lg py-2 border-b bg-[#FCFCFF]"
                        type="email"
                        placeholder="Naslov"
                        required
                    />
                </div>
                <div class="grid justify-items-start">
                    <div
                        class="text-md font-bold text-black-900 tracking-wide py-6 px-[10px]"
                    >
                        Poruka
                    </div>
                    <textarea
                        v-model="message"
                        class="input w-full text-lg py-2 border-b bg-[#FCFCFF]"
                        type="text"
                        rows="5"
                        placeholder="Poruka..."
                        required
                    />
                </div>
                <div class="mt-10">
                    <button
                        class="bg-[#EBAC0D] text-white px-4 py-2 w-1/3 rounded-md tracking-wide font-semibold font-display hover:bg-red-600"
                        type="button"
                    >
                        Pošalji
                    </button>
                </div>
            </form>
        </div>
    </section>
</template>

<script>
export default {
    name: 'contactPage',
      data() {
    return {
      formData: {
        username: '',
        message: '',
        title: ''
      }
    };
  }
}
</script>

<style scoped>
.input::placeholder {
    padding-left: 10px;
    padding-right: 10px;
}

.icon img {
    fill: black;
}

.disabled {
  pointer-events: none; 
  opacity: 0.5; 
}

.section {
    color: black;
}
</style>
